'use client';

import { ReadNextProps } from '@/ts/interfaces/readNext';
import { getRhsData } from '@/helpers/agnosticTransformer/article/utilities';
import useFeliz from '@/store/feliz/feliz';
import ArrowNext from '@/components/atoms/Icons/ArrowReadNext';
import HeadingSix from '@/components/atoms/HeadingSix/HeadingSix';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import linkHelper from '@/helpers/analytics/linkHelper';
import { getFontOnlyForPublication } from '@/helpers/utils/fonts';
import { joinClassNames } from '@/helpers/utils/classNames';
import styles from './styles/ReadNext.module.scss';

const ReadNext: React.FC<ReadNextProps> = ({
  title = 'Read Next',
  publication,
  dataTestId = 'read-next-inline',
  mockData,
  isMock,
  dataTmDataTrack = 'read-next',
}) => {
  const feliz = useFeliz();
  const readNextArticles = isMock ? mockData : feliz.state.client.recirculation?.inline?.readNext ?? [];

  if (!readNextArticles || readNextArticles.length === 0) {
    return null;
  }

  const nextArticles = readNextArticles.map((articleItem, index) => {
    const article = getRhsData({ ...articleItem });
    return (
      <span
        key={index}
        className={styles['article-link-wrapper']}
        data-tmdatatrack={dataTmDataTrack}
        data-tmdatatrack-articleid={article?.id}
        data-tmdatatrack-platform={article?.platform}
        data-tmdatatrack-source={article?.source}
        data-tmdatatrack-index={index}
      >
        <span className={styles['svg-container']}>
          <ArrowNext />
        </span>
        <a
          className={joinClassNames(
            styles['article-link'],
            publication === 'mirror' && getFontOnlyForPublication(publication)
          )}
          aria-label={article.headline}
          href={article.url}
        >
          <AnalyticsTrack
            analyticsData={linkHelper('READ_NEXT', article.url, {
              componentTitle: `ReadNext-${index}`,
              componentID: article.id,
              componentText: article.headline,
            })}
          >
            {article.headline}
          </AnalyticsTrack>
        </a>
      </span>
    );
  });

  return (
    <div className={styles['read-next-container']} tabIndex={0} aria-label="Read next" data-testid={dataTestId}>
      <HeadingSix className={styles['read-next-heading']} publication={publication}>
        {title}
      </HeadingSix>
      {nextArticles}
    </div>
  );
};

export default ReadNext;
