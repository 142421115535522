'use client';

import STATUSES from '@/constants/statuses';
import { SocialPlatformData } from '@/ts/interfaces/socialPlatforms';
import SOCIAL_PLATFORMS from '@/constants/social-platforms';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import CommentButton from '@/components/atoms/CommentButton/CommentButton';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import linkHelper from '@/helpers/analytics/linkHelper';
import CTA from '@/components/atoms/CTA/CTA';
import { Social } from '@/ts/types/socials';
import { CTADesign } from '@/ts/interfaces/cta';
import styles from './styles/ShareBar.module.scss';

const { WHATSAPP } = SOCIAL_PLATFORMS;
const { ACTIVE } = STATUSES;

export interface ShareBarProps {
  socialPlatforms: SocialPlatformData[];
  dataTmDataTrack?: string;
  displayOnlyIcons: boolean;
  iconLeft?: boolean;
  iconRight?: boolean;
  buttonDesignType: CTADesign;
  url: string;
  commentingBoxEnabled?: boolean;
  publication?: string;
  vfContainerId?: string;
  vfUUId?: string;
}
const ShareBar: React.FC<ShareBarProps> = ({
  socialPlatforms,
  dataTmDataTrack,
  displayOnlyIcons = false,
  iconRight,
  buttonDesignType,
  url,
  commentingBoxEnabled,
  publication,
  vfContainerId = '',
  vfUUId,
}) => {
  const buttonClassName = [`button-${buttonDesignType}`];

  if (displayOnlyIcons) buttonClassName.push('button-has-fixed-dimensions');

  return (
    <div className={styles['sharebar-wrapper']}>
      <div className={styles['sharebar-inner-wrapper']}>
        <ul>
          {socialPlatforms &&
            Object.values(socialPlatforms).map(
              (platform: SocialPlatformData) =>
                platform.shareContent?.status === ACTIVE && (
                  <li key={platform.name} className={platform.name === WHATSAPP ? styles.whatsapp : undefined}>
                    <AnalyticsTrack
                      analyticsData={linkHelper('SOCIAL_SHARE', url || '', {
                        componentTitle: platform.name,
                        componentID: '',
                        componentText: '',
                      })}
                    >
                      <CTA
                        href={`${platform.shareContent.url}${url}`}
                        design={buttonDesignType}
                        label={platform.name}
                        isLink={true}
                        hideLabel={iconRight}
                        ariaLabel={`Share on ${platform.name}, this link will open a new tab`}
                        icons={(platform?.name && { icon: platform.name as Social }) || undefined}
                        dataTrackName={`${platform.name}`}
                        dataTmDataTrack={dataTmDataTrack}
                        dataLinkTracking={`${platform.name.toLowerCase()}|share|top`}
                        dataTrackUrl={url}
                        social={(platform?.name as Social) || undefined}
                        target="_blank"
                        rel="noreferrer"
                      />
                    </AnalyticsTrack>
                  </li>
                )
            )}
        </ul>
        <AnalyticsTrack
          analyticsData={linkHelper('SOCIAL_SHARE', url || '', {
            componentTitle: 'Copy Link',
            componentID: '',
            componentText: '',
          })}
        >
          <CopyButton buttonClassName={buttonClassName} dtmdatatrack={dataTmDataTrack} url={url} />
        </AnalyticsTrack>
      </div>
      {commentingBoxEnabled && (
        <CommentButton publication={publication} vfContainerId={vfContainerId} vfUUId={vfUUId} />
      )}
    </div>
  );
};

export default ShareBar;
