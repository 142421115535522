'use client';

import TopSlotBox from '@/components/organisms/TopBoxWidget/TopSlotBox';
import runExperiment from '@/helpers/growthbook/runExperiment';

interface RHRTopSlotProps {
  growthbookEnabled?: boolean;
  shopWindowConfig?: Record<string, any>;
  showRHR: boolean;
}

const RHRTopSlot: React.FC<RHRTopSlotProps> = ({ showRHR, shopWindowConfig, growthbookEnabled }: RHRTopSlotProps) => {
  const topSlotExperimentVariant = growthbookEnabled ? runExperiment(/* { feature: 'nav-5244' } */) : null;

  return (
    <>
      {showRHR && (topSlotExperimentVariant === '1' || topSlotExperimentVariant === '2') ? (
        <TopSlotBox indexStart={0} shopWindowConfig={shopWindowConfig} experimentVariant={topSlotExperimentVariant} />
      ) : null}
    </>
  );
};

export default RHRTopSlot;
