import Image from '@/components/atoms/Image/Image';
import publicFolder from '@/helpers/utils/publicFolder';
import publication, { isNavigatorOnly } from '@/helpers/utils/publication';
import styles from './styles/EmbedPlaceholder.module.scss';

const EmbedPlaceholder: React.FC<{
  publication?: string;
  hidden?: boolean;
}> = ({ hidden }) => {
  const iconColorInverse = isNavigatorOnly(publication);

  return (
    <figure
      data-testid="embed-placeholder"
      className={`${styles.figure} ${hidden && styles.hidden} ${iconColorInverse && styles.placeholder}`}
    >
      <Image src={`/${publicFolder}/consent-placeholder.svg`} alt="" height="90px" loading="lazy" />
      <figcaption>Content cannot be displayed without consent</figcaption>
    </figure>
  );
};

export default EmbedPlaceholder;
