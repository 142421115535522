'use client';

import { RecommenderProps } from '@/ts';
import { publicationFont } from '@/helpers/utils/fonts';
import useFeliz from '@/store/feliz/feliz';
import { transformRhsRecommended } from '@/helpers/agnosticTransformer/article/utilities';
import { RecirculationAgnostic } from '@/ts/interfaces/agnostic';
import Skeleton from 'react-loading-skeleton';
import ErrorBoundaryFallback from '@/components/molecules/ErrorBoundaryFallback/ErrorBoundaryFallback';
import styles from './style/Recommended.module.scss';
import Teaser from '../Teaser';
import 'react-loading-skeleton/dist/skeleton.css';

const Recommended: React.FC<RecommenderProps> = ({
  publication,
  indexStart,
  indexEnd,
  title,
  rightHandRail,
  teaserType,
  dataTmDataTrack,
  dataTestId,
  isMock,
  mockData,
  showTag,
}) => {
  const fontClass = publicationFont(publication);
  const felizStore = useFeliz();
  const aspectRatio = rightHandRail?.recommended?.aspectRatio;
  const rhsRecommended = felizStore.state.client.recirculation?.rhs?.recommended ?? [];
  const teasersData = isMock ? (mockData as RecirculationAgnostic[]) : transformRhsRecommended(rhsRecommended);

  const slicedTeasersData = teasersData.slice(indexStart, indexEnd);

  return (
    <ErrorBoundaryFallback>
      <div className={styles['recommended-wrapper']} data-testid={dataTestId}>
        {title && <div className={`${styles.heading} ${fontClass}`}>{title}</div>}
        <div>
          {slicedTeasersData.length > 0
            ? slicedTeasersData.map((item, index) => (
                <Teaser
                  key={item.id ?? `teaser-${index}-${item.id}`}
                  data={item}
                  dataTmdatatrack={dataTmDataTrack}
                  index={index}
                  teaserType={teaserType}
                  aspectRatio={aspectRatio}
                  enableAnalytics={true}
                  eventName="RECOMMENDED"
                  publication={publication}
                  AnalyticData={{
                    componentTitle: `Recommendation-${index}`,
                    componentID: item.id,
                    componentText: item.headline,
                  }}
                  showTag={showTag}
                />
              ))
            : Array.from({ length: (indexEnd || 30) - indexStart }).map((_, index) => (
                <div className={styles['skeletton-item']} key={index} data-testid="teaser-placeholder">
                  <div className={styles['image-wrapper']}>
                    <Skeleton height="95px" />
                  </div>
                  <div className={styles['teaser-link']}>
                    <Skeleton count={4} />
                  </div>
                </div>
              ))}
        </div>
      </div>
    </ErrorBoundaryFallback>
  );
};

export default Recommended;
