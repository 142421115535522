'use client';

import { ReadMoreRecommendedProps } from '@/ts/interfaces/readMoreRecommended';
import { getRhsData } from '@/helpers/agnosticTransformer/article/utilities';
import useFeliz from '@/store/feliz/feliz';
import runExperiment from '@/helpers/growthbook/runExperiment';
import styles from './styles/ReadMoreRecommended.module.scss';

const ReadMoreRecommended: React.FC<ReadMoreRecommendedProps> = ({
  title,
  dataTestId,
  dataTmDataTrack,
  growthbookEnabled,
}) => {
  const feliz = useFeliz();

  const readMoreRecommendedArticles = feliz.state.client.recirculation?.inline?.readMostRecommender ?? [];

  const isExperimentOn = growthbookEnabled ? runExperiment(/* { feature: 'nav-5484' } */) === '1' : false;

  if (!readMoreRecommendedArticles || readMoreRecommendedArticles.length === 0) {
    return null;
  }

  const moreRecommendedArticles = readMoreRecommendedArticles.map((articleItem, index) => {
    const article = getRhsData({ ...articleItem });
    return (
      <p key={index} className={styles['article-link-wrapper']} data-testid={`${dataTestId}-article`}>
        {!isExperimentOn ? <span className={styles['read-more-heading']}>{title}:</span> : ''}

        <a
          className={isExperimentOn ? styles['article-link-no-title'] : styles['article-link']}
          aria-label={article.headline}
          href={article.url}
          data-tmdatatrack={dataTmDataTrack}
          data-tmdatatrack-articleid={article?.id}
          data-tmdatatrack-platform={article?.platform}
          data-tmdatatrack-source={article?.source}
        >
          {article.headline}
        </a>
      </p>
    );
  });

  return (
    <>
      {
        <div className={styles['read-more-container']} tabIndex={0} aria-label="Read more" data-testid={dataTestId}>
          {moreRecommendedArticles}
        </div>
      }
    </>
  );
};

export default ReadMoreRecommended;
